import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  EmailIcon,
} from 'react-share';

const { envVars } = require('next/config').default().publicRuntimeConfig;

const StyledRoot = styled('div')(({ theme }) => ({
  '&.root': {
    // transformOrigin: "top center",
    // transform: "scale(0.9)",
    flexDirection: 'column',
    display: 'flex',
    alignItem: 'left',
  },
  '& .iconsWraper': {
    display: 'flex',
    flexFlow: 'row',
    alignItem: 'center',
    justifyContent: 'flex-start',
  },
  '& .shareItem': {
    marginRight: 4,
    lineHeight: '0 !important',
  },
  '& .head': {
    color: theme.palette.grey[300],
    marginBottom: 8,
  },
}));
const Share = ({
  text,
  shareUrl,
  sharedTitle,
  emailSubject,
  emailBody,
  className,
}) => {
  const { t } = useTranslation('fe_er_share_component');
  const title = sharedTitle || document.getElementsByTagName('title')[0].text;
  return (
    <StyledRoot className={`root ${className}`}>
      {text && (
        <Typography variant="body2" className="head">
          {t('fe_er_share_component:share_with_friends')}
        </Typography>
      )}
      <Box className="iconsWraper">
        <FacebookShareButton url={shareUrl} quote={title} className="shareItem">
          <FacebookIcon className="something" size={32} round />
        </FacebookShareButton>

        <TwitterShareButton url={shareUrl} className="shareItem" title={title}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>

        <EmailShareButton
          url={shareUrl}
          className="shareItem"
          subject={emailSubject || title}
          body={emailBody || 'body'}>
          <EmailIcon size={32} round />
        </EmailShareButton>
        <FacebookMessengerShareButton
          url={shareUrl}
          className="shareItem"
          appId={envVars.FB_KEY}>
          <FacebookMessengerIcon size={32} round />
        </FacebookMessengerShareButton>
      </Box>
    </StyledRoot>
  );
};

export { Share };
