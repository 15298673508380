import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ImgBox } from 'views/common/components';
import { ReferralCode } from 'views/common/components/Logical/ReferralCode';

const StyledBox = styled(Box)(({ theme, bodyBGColor }) => ({
  display: 'block',
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.secondary.light}`,
  height: theme.spacing(15),
  width: '100%',
  [theme.breakpoints.down('xl')]: {
    height: theme.spacing(11.125),
  },
  [theme.breakpoints.down('lg')]: {
    height: theme.spacing(15),
  },
  [theme.breakpoints.down('md')]: {
    height: theme.spacing(11.125),
  },
  position: 'relative',
  '& .RCCardContant': {
    alignItems: 'center',
    position: 'relative',
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: theme.spacing(2, 0),
  },
  '& .RCLeftSide': {
    width: theme.spacing(12.875),
    [theme.breakpoints.down('xl')]: {
      width: theme.spacing(9.75),
    },
    height: '100%',
    textAlign: 'center',
    borderRight: `1px dashed ${theme.palette.grey[300]}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    flexDirection: 'column',
  },
  '& .RCValue': {
    color: theme.palette.secondary.main,
  },
  '& .RCIcon': {
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    background: theme.palette.primary.main,
    borderRadius: '50%',
    [theme.breakpoints.down('xl')]: {
      width: theme.spacing(3.75),
      height: theme.spacing(3.75),
    },
  },
  '& .RCRightSide': {
    padding: theme.spacing(0, 2),
    width: '100%',
  },
  '& .RCTitle': {
    color: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
    [theme.breakpoints.down('xl')]: {
      fontSize: theme.spacing(1.75),
      lineHeight: theme.spacing(3.25),
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.spacing(2),
      lineHeight: theme.spacing(4),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(1.75),
      lineHeight: theme.spacing(3.25),
    },
  },
  '& .RCProgressContiner': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .RCProgress': {
    width: `${theme.spacing(3)} !important`,
    height: `${theme.spacing(3)} !important`,
    color: theme.palette.secondary.light,
  },
  '& .RCProgressBorder': {
    width: theme.spacing(3),
    height: theme.spacing(3),
    opacity: '0.5',
    border: `2px solid ${theme.palette.secondary.light}`,
    borderRadius: '50%',
    position: 'absolute',
  },
  '& .RCLockIcon': {
    width: theme.spacing(1.7),
    height: theme.spacing(1.68),
    position: 'absolute',
    color: theme.palette.secondary.light,
  },
  '& .RCDesc': {
    color: theme.palette.secondary.light,
    [theme.breakpoints.down('xl')]: {
      fontSize: theme.spacing(1.5),
      lineHeight: theme.spacing(2),
    },
  },
  '& .RCNoteIcon': {
    marginLeft: theme.spacing(0.25),
    position: 'relative',
    top: theme.spacing(0.5),
    fontSize: theme.spacing(2),
  },
  '& .RCReferralText': {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
  },
  '& .RCReferralIcon': {
    width: theme.spacing(2),
    height: theme.spacing(1.5),
    fill: theme.palette.primary.main,
  },
  '& .RCExperationText': {
    color: theme.palette.error.main,
    [theme.breakpoints.down('xl')]: {
      fontSize: theme.spacing(1.5),
      lineHeight: theme.spacing(2),
    },
  },
  '& .RCCircle': {
    position: 'absolute',
    backgroundColor: bodyBGColor,
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: '50%',
    border: `1px solid ${theme.palette.secondary.light}`,
    borderBottomColor: 'transparent',
    borderLeftColor: 'transparent',
    '&.RCCircleLeft': {
      width: theme.spacing(4),
      height: theme.spacing(4),
      top: '50%',
      left: theme.spacing(-4),
      transform: 'rotate(46deg)  translateY(-70%)',
    },
    '&.RCCircleTop': {
      top: theme.spacing(-1),
      left: theme.spacing(9.75),
      transform: 'rotate(135deg)',
      [theme.breakpoints.down('xl')]: {
        left: theme.spacing(7.25),
      },
    },
    '&.RCCircleBottom': {
      bottom: theme.spacing(-1),
      left: theme.spacing(9.75),
      transform: 'rotate(-45deg)',
      [theme.breakpoints.down('xl')]: {
        left: theme.spacing(7.25),
      },
    },
  },
  '&.RCDisapled': {
    border: `1px solid ${theme.palette.grey[100]}`,
    backgroundColor: theme.palette.grey[50],
    '& .RCValue': {
      color: theme.palette.secondary.light,
    },
    '& .RCIcon': {
      background: theme.palette.common.white,
    },
    '& .RCTitle': {
      color: theme.palette.secondary.light,
    },
    '& .RCCircle': {
      borderColor: theme.palette.grey[100],
      borderBottomColor: 'transparent',
      borderLeftColor: 'transparent',
    },
  },
  '&.RCSmall': {
    height: theme.spacing(9.875),
    '& .RCIcon': {
      width: theme.spacing(3.75),
      height: theme.spacing(3.75),
      '& .IBimgbox': {
        width: theme.spacing(3.75),
        height: theme.spacing(3.75),
      },
    },
    '& .RCTitle': {
      marginBottom: 0,
      fontSize: theme.spacing(1.75),
    },
    '& .RCCount': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      width: theme.spacing(2.25),
      height: theme.spacing(2),
      backgroundColor: theme.palette.primary.A000,
      borderRadius: '50%',
      fontSize: theme.spacing(1.25),
      color: theme.palette.secondary.main,
    },
    '& .RCLeftSide': {
      width: theme.spacing(8.375),
      flex: '0 0 auto',
    },
    '& .RCBGContiner': {
      width: '100%',
      height: '100%',
    },
    '& .RCValue': {
      fontSize: theme.spacing(1.75),
    },
    '& .RCCircleTop, .RCCircleBottom': {
      left: theme.spacing(7.25),
    },
  },
}));
export const StyledImgBox = styled(ImgBox)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    width: `${theme.spacing(3.75)} !important`,
    height: `${theme.spacing(3.75)} !important`,
  },
}));

export const StyledReferralCode = styled(ReferralCode)(({ theme }) => ({
  '& .RCTextAndBtn': {
    border: 0,
    width: 'fit-content',
    margin: 0,
    padding: 0,
  },
  '& .RCCodeText': {
    fontSize: theme.spacing(1.5),
    fontWeight: 400,
    border: '0  !important',
    padding: `${theme.spacing(0, 0.5, 0, 0)} !important`,
    lineHeight: theme.spacing(2.375),
    minHeight: 'unset !important',
    minWidth: 'unset !important',
  },
  '& .RCButton': {
    color: `${theme.palette.primary.main} !important`,
    backgroundColor: 'transparent',
    minHeight: 'unset !important',
    minWidth: 'unset !important',
    padding: 0,
    '&:hover,:active': {
      boxShadow: 'none',
      backgroundColor: 'transparent !important',
    },
    '& .RCCopyIcon': {
      width: theme.spacing(1.25),
      height: theme.spacing(1.5),
    },
  },
}));
export default StyledBox;
