import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import StyledBox from 'views/common/components/Logical/ReferralCode/ReferralCode.styles';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import copy from 'copy-to-clipboard';
import { Typography700 } from 'views/common/components';
import { useTranslation } from 'next-i18next';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import theme from 'lib/config/theme';

const CustomToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: `${theme.spacing(0, 0, 0.66, 0)} !important`,
    '& .CTIcon': {
      marginRight: theme.spacing(2),
      fontSize: theme.spacing(2),
    },
    '& .CTText': {
      lineHeight: theme.spacing(2),
    },
  },
});

const ReferralCode = (props) => {
  const { className, referralCode, displayedReferralCode } = props;
  const { t } = useTranslation('fe_er_referralCode_component');
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCopyBtnClick = () => {
    copy(referralCode);
    setOpen(true);
  };

  return (
    <Box className={className}>
      <CustomToolTip
        open={open}
        leaveDelay={6000}
        onClose={handleClose}
        title={(
          <>
            <CheckCircleOutlineIcon className="CTIcon" />
            <Typography variant="caption" className="CTText">
              {t('fe_er_referralCode_component:link_copied')}
            </Typography>
          </>
        )}
        placement="top">
        <StyledBox
          className="RCTextAndBtn"
          display="flex"
          alignItems="center"
          justifyContent="start"
          onClick={handleCopyBtnClick}>
          <Typography700
            variant="h5"
            component="p"
            className="RCCodeText"
            color="primary">
            {displayedReferralCode || referralCode}
          </Typography700>
          <Button
            className="RCButton"
            variant="contained"
            color="primary"
            onClick={handleCopyBtnClick}>
            <FileCopyIcon className="RCCopyIcon" />
          </Button>
        </StyledBox>
      </CustomToolTip>
    </Box>
  );
};

export { ReferralCode };
