import React from 'react';
import { Typography } from '@mui/material';
import StyledBox, {
  StyledReferralCode,
  StyledsShare,
} from 'views/common/components/UI/Referral/Referral.styles';
import ReferralGift from 'views/common/components/UI/Icons/rewards/ReferralGift';
import { Typography700 } from 'views/common/components';
import { useTranslation } from 'next-i18next';

const Referral = (props) => {
  const {
    referralValue, referralData, className, shareData,
  } = props;
  const { t } = useTranslation('fe_er_common_referral');

  return (
    <StyledBox className={className}>
      <ReferralGift className="RIcon" />
      <Typography700 variant="h4" className="RTitle">
        {t('fe_er_common_referral:earn_referral_credits', {
          value: referralValue,
        })}
      </Typography700>
      <Typography variant="body2" className="RDesc">
        {t('fe_er_common_referral:earn_referral_Desc', {
          value: referralValue,
        })}
      </Typography>
      <StyledReferralCode {...referralData} />
      <StyledsShare {...shareData} />
    </StyledBox>
  );
};

export { Referral };
