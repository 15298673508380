import { Accordion } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '&.CAAccordion': {
    boxShadow: 'none',
    display: 'block',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    '&:before': {
      height: 0,
    },
    marginBottom: theme.spacing(3),
    '&:last-of-type': {
      borderBottom: '0px',
    },
    '& p:not(.MuiTypography-root)': {
      marginTop: 0,
      marginBottom: theme.spacing(0.5),
    },
  },
  '& .CAAccordionTitle': {
    minHeight: 'unset !important',
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(0, 0, 2, 0),
    '& .MuiAccordionSummary-content': {
      margin: '0 !important',
    },
  },
  '& .CAAccordionTitleIcon': {
    color: theme.palette.grey[900],
  },
  '& .CAAccordionTitleText': {
    color: theme.palette.secondary.dark,
    textTransform: 'capitalize',
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.spacing(2.625),
    },
    [theme.breakpoints.down('lg')]: {
      lineHeight: theme.spacing(3.25),
    },
  },
  '& .CAAccordionBody': {
    padding: theme.spacing(0, 4, 1, 0),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0, 3, 1, 0),
    },
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
  '& .CAADesc': {
    color: theme.palette.grey[400],
  },
}));

export default StyledAccordion;
