/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  Box,
  Typography,
  Tooltip,
  CircularProgress,
  useTheme,
} from '@mui/material';
import StyledBox, {
  StyledImgBox,
  StyledReferralCode,
} from 'views/common/components/UI/RewardCard/RewardCard.styles';
import { Typography700 } from 'views/common/components';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import LockIcon from '@mui/icons-material/Lock';

const RewardCard = (props) => {
  const theme = useTheme();
  const {
    reward,
    className,
    smallSize = false,
    count,
    bodyBGColor = theme.palette.common.white,
  } = props;

  return (
    <StyledBox
      component={reward.href && NavigatorLink}
      href={reward.href}
      className={`${reward.disabled && 'RCDisapled'} ${className} ${
        smallSize && 'RCSmall'
      } `}
      bodyBGColor={bodyBGColor}>
      <Box className="RCCardContant">
        <Box className="RCLeftSide">
          <Box className="RCIcon">
            <StyledImgBox
              imgWidth="36px"
              imgHeight="36px"
              imgObjectFit="contain"
              image={{
                src: reward.imgSrc,
                alt: reward.title,
              }}
            />
          </Box>
          {reward.value && (
            <Typography700 variant="h5" component="p" className="RCValue">
              {reward.value}
            </Typography700>
          )}
        </Box>
        <Box className="RCRightSide">
          <Typography700 variant="h4" component="p" className="RCTitle">
            {reward.title}
            {reward.progress != null && (
              <Box className="RCProgressContiner">
                <CircularProgress
                  variant="determinate"
                  value={reward.progress}
                  className="RCProgress"
                />
                <LockIcon className="RCLockIcon" />
                <Box className="RCProgressBorder" />
              </Box>
            )}
          </Typography700>
          {!smallSize && (
            <Typography variant="body2" className="RCDesc">
              {reward.desc}
              {reward.note && (
                <Tooltip
                  title={reward.note}
                  enterTouchDelay={0}
                  leaveTouchDelay={10000}>
                  <InfoOutlinedIcon className="RCNoteIcon" fontSize="small" />
                </Tooltip>
              )}
            </Typography>
          )}
          {reward.experationDesc && !smallSize && (
            <Typography variant="body2" className="RCExperationText">
              {reward.experationDesc}
              {reward.experationNote && (
                <Tooltip
                  title={reward.experationNote}
                  enterTouchDelay={0}
                  leaveTouchDelay={10000}>
                  <InfoOutlinedIcon className="RCNoteIcon" fontSize="small" />
                </Tooltip>
              )}
            </Typography>
          )}
          {reward.referralCode && !smallSize && (
            <StyledReferralCode
              referralCode={reward.referralCode}
              displayedReferralCode={reward.displayedReferralCode}
            />
          )}
          {count && count > 1 && smallSize && (
            <Typography component="span" className="RCCount">
              {count}
            </Typography>
          )}
        </Box>
      </Box>
      <Box className="RCCircle RCCircleLeft" />
      <Box className="RCCircle RCCircleTop" />
      <Box className="RCCircle RCCircleBottom" />
    </StyledBox>
  );
};

export { RewardCard };
