import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ReferralGift = (props) => {
  const { className } = props;
  return (
    <SvgIcon
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="81"
      height="83"
      viewBox="0 0 81 83"
      fill="none">
      <path
        d="M3.59626 73.9017C3.68151 75.7351 7.83985 77.0312 12.8842 76.7967L66.4496 74.3058C71.494 74.0712 75.5141 72.3948 75.4288 70.5614C75.3436 68.728 66.1409 67.6664 66.1409 67.6664C66.1409 67.6664 13.979 64.6206 8.42623 68.2222C2.87342 71.8239 3.511 72.0683 3.59626 73.9017Z"
        fill="#FFECDD"
      />
      <path
        d="M50.3695 36.1175C49.6551 36.6723 46.1993 40.7824 47.3053 41.206C48.4113 41.6296 51.389 40.6771 52.0934 40.1238C52.2758 40.0055 52.4347 39.8302 52.5604 39.6088C52.686 39.3874 52.7757 39.1245 52.8239 38.8365C52.8721 38.5485 52.8777 38.2415 52.8404 37.9346C52.8031 37.6276 52.7237 37.3273 52.6071 37.0521C52.4905 36.7769 52.3392 36.5328 52.1626 36.3348C51.986 36.1369 51.7878 35.9893 51.5804 35.9013C51.3729 35.8133 51.1606 35.7868 50.9566 35.8233C50.7526 35.8598 50.5612 35.9586 50.3944 36.1135L50.3695 36.1175Z"
        fill="#FFC396"
      />
      <path
        d="M48.2638 45.9775H20.9497V70.5419H48.2638V45.9775Z"
        fill="#FFC396"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7739 45.9774C19.7739 45.3316 20.3005 44.8081 20.95 44.8081H48.264C48.9136 44.8081 49.4401 45.3316 49.4401 45.9774V70.5419C49.4401 71.1877 48.9136 71.7112 48.264 71.7112H20.95C20.3005 71.7112 19.7739 71.1877 19.7739 70.5419V45.9774ZM22.126 47.1468V69.3725H47.088V47.1468H22.126Z"
        fill="#303030"
      />
      <path
        d="M50.6032 38.5766L18.2485 38.4665L18.2286 44.3151L50.5833 44.4252L50.6032 38.5766Z"
        fill="#FFC396"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6723 38.462C17.6745 37.8162 18.2028 37.2945 18.8523 37.2967L51.207 37.4068C51.8565 37.409 52.3812 37.9343 52.379 38.5801L52.3591 44.4288C52.357 45.0746 51.8286 45.5963 51.1791 45.5941L18.8244 45.484C18.1749 45.4818 17.6502 44.9565 17.6524 44.3107L17.6723 38.462ZM20.0204 39.6394L20.0084 43.1493L50.0111 43.2514L50.023 39.7415L20.0204 39.6394Z"
        fill="#303030"
      />
      <path
        d="M34.9755 39.7447L20.1147 39.6721L20.0983 43.0374L34.959 43.1099L34.9755 39.7447Z"
        fill="#FF9547"
      />
      <path
        d="M34.4624 47.198H22.1699V69.4291H34.4624V47.198Z"
        fill="#FF9547"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5915 47.0841L34.6743 69.5765L32.3205 69.5852L32.2377 47.0928L34.5915 47.0841Z"
        fill="#303030"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.244 51.4059C42.9818 51.4059 43.5798 52.0005 43.5798 52.734V57.8849C43.5798 57.897 43.5796 57.909 43.5793 57.921L43.4283 63.453C43.4083 64.1863 42.7943 64.7646 42.0568 64.7447C41.3194 64.7248 40.7378 64.1142 40.7578 63.381L40.9083 57.8669V52.734C40.9083 52.0005 41.5063 51.4059 42.244 51.4059Z"
        fill="white"
      />
      <path
        d="M39.7983 29.0855C38.7894 30.2075 37.6224 33.2337 36.295 37.0769C39.9831 35.2746 42.8668 33.7641 43.8597 32.6506C44.1244 32.3915 44.3332 32.081 44.4729 31.7383C44.6127 31.3956 44.6806 31.028 44.6724 30.6583C44.6641 30.2886 44.58 29.9247 44.4251 29.589C44.2702 29.2534 44.0479 28.9533 43.7719 28.7072C43.4959 28.4611 43.1722 28.2744 42.8208 28.1585C42.4694 28.0427 42.0977 28.0002 41.7289 28.0337C41.3601 28.0672 41.002 28.1759 40.6768 28.3533C40.3515 28.5306 40.0661 28.7727 39.8382 29.0645L39.7983 29.0855Z"
        fill="#FFC396"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.1167 27.3263C40.5838 27.0716 41.0981 26.9154 41.6276 26.8674C42.1571 26.8193 42.6903 26.8803 43.1942 27.0464C43.698 27.2126 44.1619 27.4802 44.5571 27.8326C44.9523 28.185 45.2706 28.6148 45.4923 29.0953C45.7141 29.5757 45.8347 30.0969 45.8465 30.6265C45.8583 31.1561 45.761 31.683 45.5605 32.1746C45.3651 32.6537 45.0756 33.0893 44.7091 33.4555C44.0639 34.1647 42.9476 34.8934 41.6659 35.6216C40.3253 36.3833 38.6538 37.2253 36.8079 38.1274C36.3808 38.3361 35.8708 38.2686 35.5155 37.9563C35.1603 37.6439 35.0296 37.1482 35.1844 36.7001C35.8497 34.774 36.484 33.0251 37.0842 31.6091C37.6665 30.2354 38.2745 29.0295 38.925 28.306C38.9592 28.2681 38.9956 28.2325 39.0342 28.1994C39.3392 27.8463 39.7058 27.5504 40.1167 27.3263ZM41.8299 29.1993C41.6217 29.2182 41.4198 29.2796 41.2365 29.3795C41.0532 29.4795 40.8922 29.616 40.7635 29.7807C40.7121 29.8466 40.6539 29.9066 40.59 29.96C40.2415 30.3934 39.7897 31.2327 39.2475 32.5118C38.9675 33.1723 38.6765 33.9175 38.3738 34.7333C39.1611 34.3296 39.8778 33.9477 40.5098 33.5886C41.8223 32.8429 42.631 32.2698 42.9832 31.8747C43.001 31.8548 43.0195 31.8354 43.0386 31.8167C43.188 31.6706 43.3059 31.4952 43.3849 31.3014C43.464 31.1075 43.5025 30.8993 43.4979 30.6895C43.4932 30.4797 43.4454 30.2729 43.3574 30.0822C43.2693 29.8914 43.143 29.7208 42.9863 29.5811C42.8296 29.4414 42.646 29.3355 42.447 29.2699C42.2481 29.2044 42.038 29.1804 41.8299 29.1993Z"
        fill="#303030"
      />
      <path
        d="M33.5626 28.9786C34.4936 30.1444 35.5024 33.2341 36.6841 37.1406C33.0993 35.1407 30.3018 33.476 29.3708 32.3102C29.1205 32.0371 28.929 31.7158 28.808 31.366C28.687 31.0162 28.6392 30.6455 28.6675 30.2768C28.6958 29.9081 28.7996 29.5492 28.9725 29.2225C29.1454 28.8958 29.3836 28.6081 29.6726 28.3774C29.9615 28.1467 30.2948 27.9778 30.652 27.8812C31.0092 27.7845 31.3826 27.7623 31.7491 27.8157C32.1155 27.8692 32.4672 27.9973 32.7823 28.192C33.0975 28.3867 33.3693 28.6439 33.5811 28.9477L33.5626 28.9786Z"
        fill="#FFC396"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.168 29.1863C31.9904 29.0765 31.7921 29.0043 31.5853 28.9741C31.3784 28.9439 31.1674 28.9564 30.9652 29.0111C30.763 29.0658 30.5739 29.1615 30.4098 29.2926C30.2458 29.4236 30.1104 29.587 30.0121 29.7727C29.9138 29.9584 29.8549 30.1623 29.8388 30.3715C29.8228 30.5808 29.8499 30.7908 29.9184 30.9887C29.9868 31.1865 30.095 31.368 30.2362 31.522C30.2543 31.5417 30.2717 31.5621 30.2884 31.583C30.6187 31.9965 31.395 32.6127 32.6652 33.4286C33.2638 33.8132 33.9423 34.2239 34.6874 34.6593C34.4244 33.8425 34.1717 33.0947 33.9274 32.4291C33.4079 31.014 32.9777 30.1203 32.646 29.705C32.584 29.6273 32.533 29.5436 32.4933 29.4559C32.3981 29.3516 32.2886 29.2608 32.168 29.1863ZM31.9138 26.6566C32.4399 26.7333 32.945 26.9172 33.3976 27.1969C33.8503 27.4766 34.2407 27.846 34.5447 28.2821C34.5928 28.3511 34.6328 28.4241 34.6645 28.4997C35.191 29.2591 35.6723 30.3806 36.1323 31.6337C36.6615 33.0755 37.2178 34.8539 37.8087 36.8071C37.9469 37.2641 37.7941 37.7577 37.4221 38.0552C37.0501 38.3527 36.5333 38.3948 36.1153 38.1616C34.321 37.1606 32.6976 36.2292 31.4004 35.3958C30.1602 34.5991 29.085 33.8109 28.4793 33.0677C28.1332 32.6821 27.8678 32.2315 27.6986 31.7425C27.5251 31.2408 27.4566 30.7094 27.4971 30.1812C27.5377 29.653 27.6863 29.1392 27.9338 28.6714C28.1813 28.2037 28.5225 27.7918 28.9362 27.4614C29.35 27.131 29.8277 26.8889 30.3398 26.7503C30.852 26.6118 31.3877 26.5798 31.9138 26.6566Z"
        fill="#303030"
      />
      <path
        d="M17.6224 38.1662C16.8564 38.2424 12.7388 39.3915 13.5547 39.9751C14.3705 40.5587 17.1712 40.985 17.9284 40.9064C18.1172 40.8997 18.3027 40.8544 18.4732 40.7732C18.6437 40.6921 18.7956 40.5768 18.9196 40.4346C19.0436 40.2923 19.1369 40.1262 19.1939 39.9464C19.2508 39.7666 19.2702 39.577 19.2507 39.3894C19.2313 39.2018 19.1734 39.0201 19.0807 38.8556C18.988 38.6911 18.8625 38.5473 18.712 38.4332C18.5614 38.3191 18.389 38.237 18.2054 38.192C18.0218 38.1471 17.831 38.1403 17.6447 38.172L17.6224 38.1662Z"
        fill="#FFC396"
      />
      <path
        d="M57.5507 46.6594C56.7846 46.7356 52.667 47.8847 53.4829 48.4683C54.2988 49.0519 57.0995 49.4782 57.8566 49.3996C58.0455 49.3929 58.2309 49.3476 58.4014 49.2664C58.5719 49.1852 58.7238 49.07 58.8478 48.9277C58.9718 48.7855 59.0651 48.6194 59.1221 48.4396C59.1791 48.2598 59.1984 48.0702 59.1789 47.8826C59.1595 47.6949 59.1016 47.5132 59.0089 47.3488C58.9163 47.1843 58.7907 47.0405 58.6402 46.9264C58.4896 46.8122 58.3172 46.7302 58.1336 46.6852C57.95 46.6403 57.7592 46.6335 57.5729 46.6651L57.5507 46.6594Z"
        fill="#FE7B10"
      />
      <path
        d="M62.4808 44.8299C63.1438 45.2212 67.1443 46.7282 66.8312 45.7752C66.518 44.8222 64.5109 42.823 63.8537 42.4388C63.6975 42.3326 63.5211 42.2595 63.3356 42.2242C63.1501 42.1889 62.9594 42.1921 62.7753 42.2335C62.5913 42.275 62.4177 42.3538 62.2655 42.4652C62.1133 42.5765 61.9857 42.718 61.8904 42.8809C61.7952 43.0437 61.7345 43.2245 61.7121 43.4119C61.6896 43.5994 61.7059 43.7895 61.7598 43.9706C61.8138 44.1516 61.9044 44.3198 62.0259 44.4645C62.1474 44.6093 62.2973 44.7276 62.4663 44.8121L62.4808 44.8299Z"
        fill="#FFC396"
      />
      <path
        d="M23.2155 38.2893C23.2196 38.9016 23.8272 42.2474 24.3494 41.644C24.8716 41.0407 25.415 38.8537 25.4084 38.2483C25.417 38.0982 25.3947 37.948 25.3429 37.807C25.2912 37.666 25.211 37.5372 25.1075 37.4285C25.0039 37.3199 24.8792 37.2337 24.741 37.1754C24.6028 37.1171 24.454 37.0878 24.304 37.0895C24.1539 37.0911 24.0058 37.1236 23.8687 37.1849C23.7316 37.2462 23.6085 37.3351 23.5071 37.4459C23.4056 37.5568 23.3279 37.6873 23.2788 37.8294C23.2297 37.9716 23.2103 38.1222 23.2217 38.2721L23.2155 38.2893Z"
        fill="#FFC396"
      />
      <path
        d="M13.473 46.4156C14.0567 46.5217 17.9225 46.3478 17.956 45.5655C17.9895 44.7832 16.5504 43.6854 15.9703 43.5838C15.8383 43.5454 15.6706 43.5468 15.4774 43.5878C15.2842 43.6288 15.0698 43.7086 14.8473 43.8222C14.6248 43.9358 14.3991 44.0807 14.1841 44.2482C13.9691 44.4156 13.7695 44.6018 13.5975 44.7954C13.4255 44.9889 13.2848 45.1856 13.1843 45.3734C13.0837 45.5611 13.0254 45.7358 13.0128 45.8866C13.0003 46.0375 13.0339 46.1613 13.1115 46.2504C13.1891 46.3394 13.309 46.3919 13.4639 46.4044L13.473 46.4156Z"
        fill="#FFC396"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M29.5118 20.6564C30.3422 21.1465 35.353 23.0341 34.9608 21.8404C34.5686 20.6467 32.0546 18.1426 31.2315 17.6614C31.0357 17.5283 30.8148 17.4368 30.5825 17.3926C30.3501 17.3484 30.1113 17.3524 29.8807 17.4043C29.6502 17.4562 29.4328 17.5549 29.2422 17.6944C29.0515 17.8339 28.8916 18.0111 28.7724 18.2151C28.6531 18.419 28.5771 18.6454 28.5489 18.8802C28.5208 19.115 28.5411 19.3532 28.6088 19.58C28.6764 19.8068 28.7898 20.0174 28.942 20.1987C29.0942 20.38 29.282 20.5282 29.4936 20.6341L29.5118 20.6564Z"
          fill="#FFAC71"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M33.5221 42.2006C33.287 43.1358 32.8948 48.4759 33.9286 47.7619C34.9625 47.0479 36.6527 43.928 36.8813 43.0023C36.9535 42.7769 36.9787 42.5392 36.9554 42.3038C36.9321 42.0684 36.8607 41.8405 36.7456 41.634C36.6306 41.4276 36.4744 41.2471 36.2866 41.1037C36.0989 40.9603 35.8837 40.8571 35.6543 40.8005C35.4249 40.7438 35.1862 40.7349 34.9531 40.7744C34.7199 40.8139 34.4973 40.9008 34.2989 41.0298C34.1005 41.1589 33.9306 41.3273 33.7998 41.5246C33.669 41.7219 33.58 41.9439 33.5383 42.1768L33.5221 42.2006Z"
          fill="#FFAC71"
        />
      </g>
      <path
        d="M37.4509 22.8279C37.3953 23.3823 37.6207 26.4684 38.1517 25.9732C38.6826 25.4779 39.3862 23.5518 39.439 23.0033C39.4613 22.8684 39.4557 22.7303 39.4225 22.5977C39.3893 22.4651 39.3293 22.3408 39.2462 22.2324C39.163 22.1241 39.0585 22.0341 38.9391 21.9679C38.8197 21.9017 38.6879 21.8609 38.552 21.8478C38.4161 21.8347 38.2789 21.8498 38.1489 21.8919C38.0189 21.9341 37.899 22.0026 37.7964 22.0931C37.6939 22.1836 37.6109 22.2941 37.5527 22.418C37.4946 22.5418 37.4624 22.6762 37.4582 22.8129L37.4509 22.8279Z"
        fill="#FFC396"
      />
    </SvgIcon>
  );
};

export default ReferralGift;
