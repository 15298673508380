import React from 'react';
import {
  Typography,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@mui/material';
import StyledAccordion from 'views/common/components/UI/CustomAccordion/CustomAccordion.styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography500 from 'views/common/components/UI/Typography500';

const CustomAccordion = (props) => {
  const {
    accordionTitle,
    accordionDesc,
    accordionDetails,
    accordionId,
    accordionHref,
    className,
    defaultExpanded = true,
  } = props;
  return (
    <StyledAccordion
      defaultExpanded={defaultExpanded}
      className={`CAAccordion ${className}`}
      elevation={0}
      id={accordionId}
      component={accordionHref && 'a'}
      href={accordionHref}
      disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="CAAccordionTitleIcon" />}
        className="CAAccordionTitle">
        <Typography500
          component="h3"
          variant="h4"
          className="CAAccordionTitleText">
          {accordionTitle}
        </Typography500>
      </AccordionSummary>
      <AccordionDetails className="CAAccordionBody">
        {accordionDesc && (
          <Typography
            variant="body1"
            component="p"
            className="CAADesc"
            dangerouslySetInnerHTML={{
              __html: accordionDesc,
            }}
          />
        )}
        {accordionDetails && <Box my={2}>{accordionDetails}</Box>}
      </AccordionDetails>
    </StyledAccordion>
  );
};

export { CustomAccordion };
