import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  '& .RCCodeText': {
    textAlign: 'center',
    minHeight: theme.spacing(5),
    height: '100%',
    lineHeight: theme.spacing(3),
    minWidth: theme.spacing(27.25),
    border: `1px dashed ${theme.palette.primary.main}`,
    cursor: 'pointer',
    padding: theme.spacing(1, 1.5),
    wordBreak: 'break-word',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      fontSize: theme.typography.pxToRem(14),
      textAlign: 'start',
    },
  },
  '& .RCButton': {
    color: theme.palette.common.white,
    minHeight: theme.spacing(5.25),
    boxShadow: 'none',
    borderRadius: '0 0.25rem 0.25rem 0',
    minWidth: theme.spacing(5.625),
    height: '100%',
    '& .RCCopyIcon': {
      height: theme.spacing(2.5),
    },
  },
}));

export default StyledBox;
