import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReferralCode } from 'views/common/components/Logical/ReferralCode';
import { Share } from 'views/common/components';

const StyledBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  '& .RIcon': {
    width: theme.spacing(10),
    height: theme.spacing(10.178),
  },
  '& .RTitle': {
    color: theme.palette.secondary.main,
    margin: theme.spacing(3, 0, 1),
  },
  '& .RDesc': {
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(3),
  },
}));
export const StyledReferralCode = styled(ReferralCode)(({ theme }) => ({
  '& .RCTextAndBtn': {
    border: `1px dashed ${theme.palette.primary.main}`,
    width: 'fit-content',
    margin: 'auto',
    padding: theme.spacing(1),
  },
  '& .RCCodeText': {
    fontSize: theme.spacing(1.75),
    fontWeight: 400,
    border: '0  !important',
    padding: `${theme.spacing(0, 1, 0, 0)} !important`,
    lineHeight: 'normal !important',
    minHeight: 'unset !important',
  },
  '& .RCButton': {
    color: `${theme.palette.primary.main} !important`,
    backgroundColor: 'transparent',
    minHeight: 'unset !important',
    minWidth: 'unset !important',
    padding: 0,
    '&:hover,:active': {
      boxShadow: 'none',
      backgroundColor: 'transparent !important',
    },
  },
}));
export const StyledsShare = styled(Share)(({ theme }) => ({
  marginTop: theme.spacing(3),
  '& .iconsWraper': {
    margin: 'auto',
  },
}));

export default StyledBox;
