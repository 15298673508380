import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';

const StyledBox = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
}));

const ReferralSkeleton = () => (
  <StyledBox>
    <Skeleton variant="rectangular" width="50px" height="60px" />
    <Skeleton variant="text" width="80%" height="40px" />
    <Skeleton variant="text" width="80%" height="100px" />
    <Skeleton variant="text" width="80%" height="40px" />
    <Skeleton variant="text" width="70%" height="40px" />
  </StyledBox>
);
export { ReferralSkeleton };
